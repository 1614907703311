<template>
  <v-form>
    <v-alert
      v-if="alert"
      border="left"
      colored-border
      color="success"
      elevation="2"
      class="alert-edit"
      type="success"
      transition="fade-transition">
      Staff successfully updated.
    </v-alert>
    <v-overlay :value="overlay">
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="user.first_name"
          outlined
          label="First name"
          prepend-icon="mdi-account"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
      <v-text-field
        v-model="user.last_name"
        outlined
        label="Last name"
        prepend-icon="mdi-account"
         hide-details="auto"
      ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="user.email"
          outlined
          label="Email address"
          prepend-icon="mdi-email"
           hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="user.password"
          outlined
          label="Password"
          prepend-icon="mdi-lock"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <DeleteStaff
      v-if="this.$store.getters.user.is_superuser"
      title="Delete Staff"
      :message="message"
      icon="delete"
      action="Delete"
      class="mr-auto"
      @submit="deleteItem(user.id)"/>
    <v-btn
      @click="updateCurrentStaff()"
      x-large
      color="primary"
      bottom
      right
      absolute
      :class="{ 'v-btn--mobile absolute' : $vuetify.breakpoint.xsOnly }"
    >
      Update Staff
    </v-btn>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex';
  import DeleteStaff from '@/components/ConfirmModal.vue';

  export default {
    name: 'EditStaff',
    components: {
      DeleteStaff
    },
    data() {
      return {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          id: '',
          password: ''
        },
        overlay: true,
        alert: false,
        message: ''
      }
    },
    created() {
      this.getCurrentStaff(Number(this.$route.params.id))
    },
    methods: {
      ...mapActions(['getStaff', 'updateStaff', 'deleteStaff']),
      getCurrentStaff(id) {
        this.getStaff({ id: id })
        .then((response) => {
          this.user = Object.assign({}, this.user, response.data )
          this.overlay = false
          this.message = `Are you sure you want to delete ${this.user.first_name} ${this.user.last_name}?`
        }, (error) => {
          console.log('error', error)
        });
      },
      updateCurrentStaff() {
        this.overlay = true
        this.updateStaff(this.user)
        .then(() => {
          this.overlay = false
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 3000)
        }, (error) => {
          console.log('error', error)
        });
      },
      deleteItem(id) {
        this.deleteStaff({id})
        .then(() => {
          this.message = 'Staff deleted. Re-routing...'
          setTimeout(() => {
            this.$router.push({ name: 'stafflist' })
          }, 3000);
        }, (error) => {
          console.log('error', error)
        });
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
