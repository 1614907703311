<template>
  <v-dialog
    class="confirm-modal"
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        small
        dark
        bottom
        left
        absolute
        color="error"
        v-bind="attrs"
        v-on="on"
      ><v-icon
          dark
          small>{{ $router.name }}
          {{ `mdi-${icon}` }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
          color="error darken-1"
          dark
        >{{ title }}</v-toolbar>
      <v-card-text class="pt-4">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="error darken-1"
          text
          @click="submit"
        >
          {{ action }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: 'Confirm',
    props: {
      action: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        dialog: false
      }
    },
    computed: {

    },
    methods: {
      submit() {
        this.$emit('submit')
      }
    }
  }
</script>
